
import axios from "axios";
import { isNil, omitBy } from "lodash";

// const BASE_URL_API = "http://localhost:5000"
const BASE_URL_API =  "https://mypersonaltrip-engine.herokuapp.com"


const buildAnswersParams = (answers, language) => {
    return omitBy({
      origin: "mypersonaltrip-form",
      language: language || "en",
      name_cust: answers?.["question-1"]?.value,
      city_pref: answers?.["question-2"]?.value?.[0],
      time_pref: answers?.["question-3"]?.value?.[0],
      budget_pref: answers?.["question-4"]?.value?.[0],
      visit_pref1: answers?.["question-5"]?.value?.[0] || "",
      visit_pref2: answers?.["question-5"]?.value?.[1] || "",
      restaurant_pref1: answers?.["question-6"]?.value?.[0] || "",
      restaurant_pref2: answers?.["question-6"]?.value?.[1] || "",
      night_pref: answers?.["question-7"]?.value?.join(','), // add multiple
      email_cust: answers?.["question-8"]?.value
    }, isNil);
  }


const getApiItinerary = async (data, language) => {
    try {
      const answers = data.answers;
      const params = buildAnswersParams(answers, language);
      await axios({
        method: "post",
        url: `${BASE_URL_API}/itinerary`,
        withCredentials: false,
        data: params,
        })
    } catch (error) {
      console.log("getApiItinerary error", error)
    }
  }

export { getApiItinerary };