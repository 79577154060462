import "@quillforms/renderer-core/build-style/style.css";
import "./styles.css";
import "./picture-choice";

import { Form } from "@quillforms/renderer-core";
import { registerCoreBlocks } from "@quillforms/react-renderer-utils";

import { getApiItinerary } from './api';
import { translate } from "./i18n";

import { useState } from "react";
import ReactFlagsSelect from "react-flags-select";

const BASE_URL_FORM_IMAGES = "https://mypersonaltrip.com/images/caroussel";

const MPT_COLORS = {
  primary: "#F4B24A",
  secondary: "#153256",
  white: "#ffffff",
}

// quillform doc https://github.com/quillforms/quillforms/blob/master/react-docs/get-started.md
registerCoreBlocks();

const getCountryLanguage = (code) => {
  return code === "US" ? "en" : "fr";
}

const getLanguageCountry = (language) => {
  return language === "en" ? "US" : "FR";
}

const MptForm = ({initialLanguage}) => {
  const [selectedLanguageCode, setSelectedLanguageCode] = useState(getLanguageCountry(initialLanguage));
  const [language, setLanguage] = useState(initialLanguage);

  function onSelectLanguage(code) {
    setSelectedLanguageCode(code);
    setLanguage(getCountryLanguage(code));
  }

  return (
    <div>
      <div style={{
        position:"absolute",
        top:"20px",
        right:"20px",
        backgroundColor: "white",
        zIndex: 100000,
        }}>
        <ReactFlagsSelect
            className="flag-selector"
            selected={selectedLanguageCode}
            onSelect={(code) => onSelectLanguage(code)}
            countries={["US", "FR"]}
            showSelectedLabel={false}
            showOptionLabel={false}
            fullWidth={false}
          />
      </div>
      <div style={{
        width: "100%",
        height: "100vh",
        }}>
        <Form
          formId="1"
          formObj={{
            theme: {
              font: "Roboto",
              backgroundColor: "#33000000",
              backgroundImage: "/form-images/background.jpeg",
              buttonsBgColor: MPT_COLORS.secondary,
              buttonsFontColor: MPT_COLORS.white,
              questionsColor: MPT_COLORS.primary,
              answersColor: MPT_COLORS.white,
              progressBarFillColor: MPT_COLORS.white,
              progressBarBgColor: MPT_COLORS.white,
            },
            messages: {
              "block.defaultThankYouScreen.label": translate("BLOCK_THANKYOU_MESSAGE", language),
              "block.shortText.placeholder": translate("BLOCK_SHORT_TEXT_PLACEHOLDER", language),
              "label.hintText.enter": translate("BLOCK_HINT_ENTER", language),
              "label.submitBtn": translate("BLOCK_SUBMIT", language),
            },
            settings: {
              disableProgressBar: true,
              animationDirection: "vertical"
            },
            blocks: [
              {
                name: "welcome-screen",
                id: "introduction",
                attributes: {
                  label: translate("TYPEFORM_WELCOME", language),
                  description: translate("TYPEFORM_DESCRIPTION", language),
                  buttonText: translate("LETSSTART", language),
                  attachment: {
                    type: "image",
                    url: "/form-images/big-logo-mypersonaltrip.png"
                  }
                }
              },
              {
                name: "short-text",
                id: "question-1",
                attributes: {
                  required: true,
                  label: translate("QUESTION_NAME", language),

                }
              },
              {
                name: "picture-choice",
                id: "question-2",
                attributes: {
                  label: translate("QUESTION_CITY", language),
                  required: true,
                  multiple: false,
                  choices: [
                    {
                      label: translate("CITY_PARIS", language),
                      value: "Paris",
                      imageUrl:
                        `${BASE_URL_FORM_IMAGES}/paris.jpg`
                    },
                    {
                      label: translate("CITY_LONDON", language),
                      value: "London",
                      imageUrl:
                        `${BASE_URL_FORM_IMAGES}/london.jpg`
                    },
                    {
                      label: translate("CITY_BARCELONA", language),
                      value: "Barcelona",
                      imageUrl:
                        `${BASE_URL_FORM_IMAGES}/barcelona.jpg`
                    },
                    {
                      label: translate("CITY_ROME", language),
                      value: "Rome",
                      imageUrl:
                        `${BASE_URL_FORM_IMAGES}/rome.jpg`
                    },
                    {
                      label: translate("CITY_LYON", language),
                      value: "Lyon",
                      imageUrl:
                        `${BASE_URL_FORM_IMAGES}/lyon.jpeg`
                    },
                    {
                      label: translate("CITY_LISBON", language),
                      value: "Lisbon",
                      imageUrl:
                        `${BASE_URL_FORM_IMAGES}/lisbon.jpeg`
                    },
                  ],
                  imgWidth: 300,
                  imgHeight: 200
                }
              },
              {
                name: "multiple-choice",
                id: "question-3",
                attributes: {
                  label: translate("QUESTION_TIME", language),
                  required: true,
                  multiple: false,
                  choices: [
                    {
                      label: `1 ${translate("DAY", language)}`,
                      value: "1",
                    },
                    {
                      label: `2 ${translate("DAYS", language)}`,
                      value: "2",
                    },
                    {
                      label: `3 ${translate("DAYS", language)}`,
                      value: "3",
                    },
                    {
                      label: `4 ${translate("DAYS", language)}`,
                      value: "4",
                    },
                    {
                      label: `5 ${translate("DAYS", language)}`,
                      value: "5",
                    },
                  ]
                }
              },
              {
                name: "multiple-choice",
                id: "question-4",
                attributes: {
                  label: translate("QUESTION_BUDGET", language),
                  required: true,
                  multiple: false,
                  choices: [
                    {
                      label: translate("PREFERENCE_BUDGET_LOW", language),
                      value: "low",
                    },
                    {
                      label: translate("PREFERENCE_BUDGET_MEDIUM", language),
                      value: "medium",
                    },
                    {
                      label: translate("PREFERENCE_BUDGET_HIGH", language),
                      value: "high",
                    }
                  ]
                }
              },
              {
                name: "picture-choice",
                id: "question-5",
                attributes: {
                  label: translate("QUESTION_ACTIVITY", language),
                  required: true,
                  multiple: true,
                  max: 2,
                  choices: [
                    {
                      value: "food",
                      label: translate("PREFERENCE_FOOD", language),
                      imageUrl:
                        `${BASE_URL_FORM_IMAGES}/food.jpg`
                    },
                    {
                      value: "museums",
                      label: translate("PREFERENCE_MUSEUMS", language),

                      imageUrl:
                        `${BASE_URL_FORM_IMAGES}/museums.jpg`
                    },
                    {
                      value: "monuments",
                      label: translate("PREFERENCE_MONUMENTS", language),
                      imageUrl:
                        `${BASE_URL_FORM_IMAGES}/monuments.jpg`
                    },
                    {
                      value: "shopping",
                      label: translate("PREFERENCE_SHOPPING", language),
                      imageUrl:
                        `${BASE_URL_FORM_IMAGES}/shopping.jpg`
                    },
                    {
                      value: "ecology",
                      label: translate("PREFERENCE_ECOLOGY", language),
                      imageUrl:
                        `${BASE_URL_FORM_IMAGES}/ecology.jpeg`
                    },
                    {
                      value: "insolite",
                      label: translate("PREFERENCE_INSOLITE", language),
                      imageUrl:
                        `${BASE_URL_FORM_IMAGES}/insolite.jpeg`
                    },
                    {
                      value: "family",
                      label: translate("PREFERENCE_FAMILY", language),
                      imageUrl:
                        `${BASE_URL_FORM_IMAGES}/family.jpeg`
                    },
                  ],
                  imgWidth: 300,
                  imgHeight: 200
                }
              },
              {
                name: "picture-choice",
                id: "question-6",
                attributes: {
                  label: translate("QUESTION_RESTAURANT", language),
                  label: "What kind of restaurant do you prefer?",
                  required: true,
                  multiple: true,
                  choices: [
                    {
                      value: "street food",
                      label: translate("PREFERENCE_STREETFOOD", language),
                      imageUrl:
                        `${BASE_URL_FORM_IMAGES}/street-food.jpg`
                    },
                    {
                      value: "convivial",
                      label: translate("PREFERENCE_CONVIVIAL", language),
                      imageUrl:
                        `${BASE_URL_FORM_IMAGES}/convivial.jpg`
                    },
                    {
                      value: "trendy",
                      label: translate("PREFERENCE_TRENDY", language),
                      imageUrl:
                        `${BASE_URL_FORM_IMAGES}/trendy.jpg`
                    },
                    {
                      value: "elegant",
                      label: translate("PREFERENCE_ELEGANT", language),
                      imageUrl:
                        `${BASE_URL_FORM_IMAGES}/elegant.jpg`
                    },
                    {
                      value: "romantic",
                      label: translate("PREFERENCE_ROMANTIC", language),
                      imageUrl:
                        `${BASE_URL_FORM_IMAGES}/romantique.png`
                    },
                    {
                      value: "traditional",
                      label: translate("PREFERENCE_TRADITIONAL", language),
                      imageUrl:
                        `${BASE_URL_FORM_IMAGES}/traditionnel.jpg`
                    },
                  ],
                  imgWidth: 300,
                  imgHeight: 200
                }
              },
              {
                name: "picture-choice",
                id: "question-7",
                attributes: {
                  label: translate("QUESTION_NIGHT", language),
                  required: false,
                  multiple: true,
                  choices: [
                    {
                      label: translate("PREFERENCE_BAR", language),
                      value: "bars",
                      imageUrl:
                        `${BASE_URL_FORM_IMAGES}/bars.jpg`
                    },
                    {
                      label: translate("PREFERENCE_CLUB", language),
                      value: "clubs",
                      imageUrl:
                        `${BASE_URL_FORM_IMAGES}/clubs.jpg`
                    },
                  ],
                  imgWidth: 300,
                  imgHeight: 200
                }
              },
              {
                name: "email",
                id: "question-8",
                attributes: {
                  required: true,
                  label: translate("QUESTION_MAIL", language),
                }
              }
            ]
          }}
          onSubmit={(data, { completeForm, setIsSubmitting }) => {
              setTimeout(async () => {
                await getApiItinerary(data, language);
                setIsSubmitting(false);
                completeForm();
              }, 500);
            }}
        />
      </div>
    </div>
  );
};

export default MptForm;