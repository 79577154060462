import "./styles.css";
import { Route, Routes } from 'react-router-dom';

import MptForm from './MptForm';

const App = () => {
  return (
      <Routes>
        <Route path="/" element={<MptForm initialLanguage="en"/>} />
        <Route path="fr" element={<MptForm initialLanguage="fr"/>} />
      </Routes>
  );
};

export default App;
