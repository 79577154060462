const i18n = {
    en: {
        CITY_PARIS: "Paris",
        CITY_BARCELONA: "Barcelona",
        CITY_LYON: "Lyon",
        CITY_LISBON: "Lisbon",
        CITY_ROME: "Rome",
        CITY_LONDON: "London",
        INTRO_PREFERENCES: 'Based on your travel preferences, we\'ll do our best to give you the best itinerary possible with',
        WITHBUDGET: 'budget',
        ACTIVITIES: 'activities',
        RESTAURANTS: 'restaurants',
        BUDGET: 'budget',
        QUESTION_STARTDAY: 'What day of the week will your visit start?',
        'LETSGO!': 'Let\'s go!',
        ADDRESSES: 'Today, Jimmy offers more <b>than 700 addresses today, famous or off-the-beaten paths</b> to personalize as much as possible your stay 🎯',
        AFTERNOON: 'Afternoon',
        AMAZINGDAYS: 'amazing days!',
        AND: 'and',
        BAR: 'Bar',
        BESTACTIVITIES: 'with the best restaurants and activities ideas for you, and a',
        LETSSTART: "Let's start",
        BREAKFAST: 'Breakfast',
        PREFERENCE_BUDGET_LOW: 'Less than 50€',
        PREFERENCE_BUDGET_MEDIUM: 'Between 50€ and 100€',
        PREFERENCE_BUDGET_HIGH: 'More than 100€',
        PREFERENCE_BUDGET_END: 'per day',
        PREFERENCE_FOOD: 'food',
        PREFERENCE_MUSEUMS: 'museums exhibitions',
        PREFERENCE_MONUMENTS: 'monuments',
        PREFERENCE_SHOPPING: 'shopping',
        PREFERENCE_ECOLOGY: 'ecology',
        PREFERENCE_FAMILY: 'family',
        PREFERENCE_INSOLITE: 'insolite',
        PREFERENCE_STREETFOOD: 'street food',
        PREFERENCE_CONVIVIAL: 'convivial',
        PREFERENCE_TRENDY: 'trendy',
        PREFERENCE_ELEGANT: 'elegant',
        PREFERENCE_ROMANTIC: 'romantic',
        PREFERENCE_TRADITIONAL: 'traditional',
        PREFERENCE_BAR: 'bars',
        PREFERENCE_CLUB: 'clubs',
        PREFERENCE_NONIGHT: 'None',
        QUESTION_NAME: 'Let\'s start with your name',
        QUESTION_CITY: 'In which city do you plan to go for your next trip?',
        QUESTION_TIME: 'How long would you stay?',
        QUESTION_TIME_CITY: 'How long would you stay in',
        QUESTION_BUDGET: 'What is your budget for each day? (except transport & accomodation)',
        QUESTION_NIGHT: 'Do you enjoy bars and clubs at night?',
        QUESTION_ACTIVITY: 'Among these proposal, choose your centers of interest.',
        QUESTION_RESTAURANT: 'What kind of restaurant do you prefer?',
        QUESTION_MAIL: 'What is your email address (to receive your personal travel guide)',
        TYPEFORM_WELCOME: 'A free personalised travel guide delivered to you in 2 minutes.',
        TYPEFORM_DESCRIPTION: 'Tell me about your travel tastes, and I will suggest you restaurants and activities for your trip.',
        TYPEFORM_START: 'Start',
        CLUB: 'Club',
        DAY: 'day',
        DAYS: 'days',
        DIDNOTDO: 'Didn\'t go',
        DIDNTDOEVERYTHING: 'I didn’t try everything but the idea was good : I liked it',
        DIDYOUENJOY: 'So, did you enjoy it?',
        DINNER: 'Dinner',
        ENDVACATIONS: 'Back home! I hope our itinerary was adapted to your bugdet 💵 and to your travel tastes 🕌?',
        FEEDBACKINTRO: 'Please tell us what activities and restaurants you went to, and what did you think about it (from 1 to 5)',
        FEEDBACKTITLE: 'Itinerary Feedback',
        FINISH: 'Send feedback',
        FOR: 'for',
        FRIDAY: 'Friday',
        FROM: 'from',
        GREATTRIP: 'Have a great trip,',
        GIVEREVIEW: 'Give a review',
        HELPUSIMPROVE: '<b>Help us improve our service!</b> It will allow an even more personnalized trip for next time! ✈️',
        HOUSINGRECOMMENDATION: 'For your accommodation during your stay, we recommend',
        JOINUS: 'Join us on',
        LEAVEMEALONE: 'leave me alone, who is Jimmy anyway?',
        LETSGO: 'Let\'s go',
        LUNCH: 'Lunch',
        MISSIONACCOMPLISH: 'Jimmy sincerely hopes you made the most of this personalized and memorable trip and hopes it matched the activities and restaurants you were looking for! Mission accomplished? 🏋️‍',
        MONDAY: 'Monday',
        MORNINGACTIVITIES: 'Morning activities',
        NICELIFE: 'Doesn\'t it sound great?',
        OURRAISONDETRE: 'Our firmest hope is to allow all travellers to live the best experience possible, the one that whichever personality each will like most, which of course means NO ADS: we will not share any personal information, pinky promise… and GDPR! 🤐',
        OVERALLFEEDBACK: 'Overall feedback on your itinerary with Mypersonaltrip',
        OVERALLFEEDBACKTEXT: 'Any comment on the itinerary or one of its restaurant/activity?',
        PERSONALIZEDITINERARY: 'personalized itinerary',
        PRACTICALINFO: 'with all the useful information about the city',
        PROGRAM: 'Just for you, a',
        RATEHOTEL: 'Your feedback on the hotel',
        REVIEWHOTEL: 'Any comment on your stay at the hotel?',
        REVIEWUS: 'If you enjoyed your stay in your hotel please take a minute to review it on ',
        ROUTESHEET: 'cheatsheet',
        SATURDAY: 'Saturday',
        SENDUS: 'Send us your',
        SUNDAY: 'Sunday',
        TESTEDAPPROVED: 'tested and approved, I’ll be talking about Jimmy to my friends!',
        THANKS: 'Thanks!',
        THURSDAY: 'Thursday',
        BLOCK_THANKYOU_MESSAGE: "All done! Thanks for your time, and check your e-mails for your guide! Also check your spams if you don't find it 😊",
        BLOCK_SHORT_TEXT_PLACEHOLDER: "Type your answer here",
        BLOCK_HINT_ENTER: "press <strong>Enter ↵</strong>",
        BLOCK_SUBMIT: "Submit",
        TODAYDIRECTION: 'Today I will take you to … ✈',
        TOFOLLOWNEXTCITIES: 'to follow the next available cities!',
        TOOPENNEWCITIES: 'in order for us to select the next cities and to give you better recommendations',
        TUESDAY: 'Tuesday',
        WEDNESDAY: 'Wednesday',
        WHATDIDYOUTHINKOF: 'What dit you think of ',
        YOURITINERARY: 'Itinerary for your trip to',
    },
    fr: {
        CITY_PARIS: "Paris",
        CITY_BARCELONA: "Barcelone",
        CITY_LYON: "Lyon",
        CITY_LISBON: "Lisbonne",
        CITY_ROME: "Rome",
        CITY_LONDON: "Londres",
        INTRO_PREFERENCES: 'Nous allons t\'envoyer nos recommandations les plus adaptées à tes choix :',
        WITHBUDGET: 'avec un budget',
        ACTIVITIES: 'des activités',
        RESTAURANTS: 'des restaurants',
        PREF_FOOD: 'nourriture',
        PREF_MUSEUMS: 'musées',
        PREF_MONUMENTS: 'monument',
        PREF_SHOPPING: 'shopping',
        PREF_ECOLOGY: 'écologique',
        PREF_FAMILY: 'familial',
        PREF_INSOLITE: 'insolites',
        PREF_STREETFOOD: 'street-food',
        PREF_CONVIVIAL: 'conviviaux',
        PREF_TRENDY: 'branché',
        PREF_ELEGANT: 'élégant',
        PREF_ROMANTIC: 'romantique',
        PREF_TRADITIONAL: 'traditionnel',
        PREF_BUDGET_LOW: 'bas',
        PREF_BUDGET_MEDIUM: 'moyen',
        PREF_BUDGET_HIGH: 'élevé',
        BUDGET: 'un budget',
        QUESTION_STARTDAY: 'Quel jour de la semaine commencera ton voyage ?',
        'LETSGO!': 'Allez, suis-moi !',
        ADDRESSES: 'Jimmy propose aujourd’hui plus de <b>700 adresses de derrière les fagots</b> pour correspondre au mieux aux goûts de chacun 🎯',
        AFTERNOON: 'Après-midi ',
        AMAZINGDAYS: 'jours de folie !',
        AND: 'et',
        BAR: 'Bar ',
        BESTACTIVITIES: 'avec les meilleures idées d’activités et restaurants pour toi et une',
        LETSSTART: "C'est parti",
        BREAKFAST: 'Petit déjeuner ',
        PREFERENCE_BUDGET_LOW: 'Moins de 50€',
        PREFERENCE_BUDGET_MEDIUM: 'Entre 50€ et 100€',
        PREFERENCE_BUDGET_HIGH: 'Plus de 100€',
        PREFERENCE_BUDGET_END: 'par jour',
        PREFERENCE_FOOD: 'nourriture',
        PREFERENCE_MUSEUMS: 'musées-expositions',
        PREFERENCE_MONUMENTS: 'monuments',
        PREFERENCE_SHOPPING: 'shopping',
        PREFERENCE_ECOLOGY: 'écologie',
        PREFERENCE_FAMILY: 'famille',
        PREFERENCE_INSOLITE: 'insolite',
        PREFERENCE_STREETFOOD: 'street food',
        PREFERENCE_CONVIVIAL: 'convivial',
        PREFERENCE_TRENDY: 'branché',
        PREFERENCE_ELEGANT: 'élégant',
        PREFERENCE_ROMANTIC: 'romantique',
        PREFERENCE_TRADITIONAL: 'traditionnel',
        PREFERENCE_BAR: 'les bars',
        PREFERENCE_CLUB: 'les clubs',
        PREFERENCE_NONIGHT: 'Aucun des deux.',
        QUESTION_NAME: "Quel est ton nom ?",
        QUESTION_CITY: 'Où souhaites-tu te rendre pour ton prochain voyage ?',
        QUESTION_TIME: 'Combien de temps veux-tu rester ?',
        QUESTION_TIME_CITY: 'Combien de temps veux-tu rester à',
        QUESTION_BUDGET: 'Quel est ton budget moyen par jour (sans le transport et le logement) ? ',
        QUESTION_NIGHT: 'Est-ce que tu aimes les bars et les boites de nuits ? ',
        QUESTION_ACTIVITY: 'Parmi ces propositions, quels sont tes centres d\'intérêts ? ',
        QUESTION_RESTAURANT: 'Quel type de restaurant préfères-tu ?',
        QUESTION_MAIL: 'Quel est ton adresse email ? (Pour reçevoir ton guide de voyage personnalisé) ',
        TYPEFORM_WELCOME: 'reçois ton guide de voyage personnalisé par email en 2 minutes ! ',
        TYPEFORM_DESCRIPTION: 'Dis-nous en plus sur tes habitudes de voyage, et nous te conseillerons les restaurants et activités les plus adaptées pour ton prochain voyage.',
        TYPEFORM_START: 'Commencer',
        CLUB: 'Boîte de nuit ',
        DAY: 'jour',
        DAYS: 'jours',
        DIDNOTDO: 'Je n\'y suis pas allé',
        DIDNTDOEVERYTHING: 'je n’ai pas tout fait mais l’idée était bonne, validé',
        DIDYOUENJOY: 'Alors, ça t\'a plu ?',
        DINNER: 'Dîner ',
        ENDVACATIONS: 'Fini les vacances ! J\'espère que notre itinéraire était adapté à ton budget 💵 et tes goûts touristiques 🕌 ?',
        FEEDBACKINTRO: 'Dis nous à quels restaurants et activités tu t\'es rendu et note les (sur une échelle de 1 à 5) ',
        FEEDBACKTITLE: 'Questionnaire retour Itinéraire',
        FINISH: 'Envoyer mon avis',
        FOR: 'pour',
        FRIDAY: 'Vendredi',
        FROM: 'de',
        GREATTRIP: 'Bon voyage,',
        GIVEREVIEW: 'Donner mon avis',
        HELPUSIMPROVE: '<b>Aide-nous à nous améliorer !</b> ça permettra de personnaliser encore mieux ton prochain voyage ! ✈️',
        HOUSINGRECOMMENDATION: 'Pour le logement durant ton séjour, nous te recommandons',
        JOINUS: 'Rejoins-nous sur',
        LEAVEMEALONE: 'lâche-moi les baskets je ne sais même pas qui t’es',
        LETSGO: 'C\'est parti',
        LUNCH: 'Déjeuner ',
        MISSIONACCOMPLISH: '‍Jimmy espère sincèrement que tu as passé un incroyable séjour, personnalisé, inoubliable et qui correspondait et aux attentes et au budget! Mission accomplie ? 🏋️‍',
        MONDAY: 'Lundi',
        MORNINGACTIVITIES: 'Activités du matin ',
        NICELIFE: 'Elle est pas belle la vie ?',
        OURRAISONDETRE: 'Notre raison d’être étant de permettre à tous les voyageurs de vivre la meilleure expérience possible, ça passe évidemment par PAS DE PUB : on ne partagera aucune information confidentielle, parole de Jimmy...et de RGPD! 🤐',
        OVERALLFEEDBACK: 'Retour global sur ton itinéraire Mypersonaltrip',
        OVERALLFEEDBACKTEXT: 'Un commentaire sur ton trajet ou une des activités et restaurants proposé(e)s?',
        PERSONALIZEDITINERARY: 'itinéraire personnalisé',
        PRACTICALINFO: 'avec les infos pratiques sur la ville',
        PROGRAM: 'Au programme, un',
        RATEHOTEL: 'Ton avis sur le séjour dans l\'hotel',
        REVIEWHOTEL: 'Un commentaire sur l\'hotel?',
        REVIEWUS: 'Si tu as apprécié le séjour dans ton hôtel, prends une minute pour laisser un avis sur ',
        ROUTESHEET: 'feuille de route',
        SATURDAY: 'Samedi',
        SENDUS: 'Envoie-nous tes',
        SUNDAY: 'Dimanche',
        TESTEDAPPROVED: 'testé et approuvé, je vais parler de Jimmy à mes proches!',
        THANKS: 'Merci !',
        BLOCK_THANKYOU_MESSAGE: "C'est fait ! Merci pour ton temps. Tu vas bientôt recevoir ton guide personnalisé, sinon vérifie dans tes spams 😊",
        BLOCK_SHORT_TEXT_PLACEHOLDER: "Entre ta réponse ici",
        BLOCK_HINT_ENTER: "appuyer sur <strong>Enter ↵</strong>",
        BLOCK_SUBMIT: "Envoyer",
        THURSDAY: 'Jeudi',
        TODAYDIRECTION: 'Aujourd’hui, je t’emmène direction… ✈',
        TOFOLLOWNEXTCITIES: 'pour être au courant des prochaines villes disponibles !',
        TOOPENNEWCITIES: 'pour qu’on puisse ouvrir d’autres villes et améliorer nos recommandations',
        TUESDAY: 'Mardi',
        WEDNESDAY: 'Mercredi',
        WHATDIDYOUTHINKOF: 'Qu\'as-tu pensé de ',
        YOURITINERARY: 'Ton itinéraire pour',
    }
  };
  
  function translate(key, language = 'en') {
    return i18n[language][key] ? i18n[language][key] : key;
  }
  
  
export { translate };

  